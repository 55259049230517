import React from 'react';
import RosterGrid from './rosterGrid';
import { barcelonaConfig } from '../../../utils/artDetailsUtils';

// Styles
import styles from './info.module.css'

interface BarcelonaDetailsProps {
  fullWizardData: any;
  displayRoster: boolean;
  isPrintView?: boolean;
  checkMappedCards?: () => string;
}

const BarcelonaDetails: React.FC<BarcelonaDetailsProps> = ({ fullWizardData, displayRoster, isPrintView = false, checkMappedCards }) => {
  const rosterData = { qty: fullWizardData?.qty, isBarcelona: true };
  const columns = checkMappedCards ? checkMappedCards() : styles.twoColumns;
  const rowStyle = isPrintView
    ? "flex flex-row flex-wrap w-full px-0 py-[0.25em] border-b-[rgb(218,218,218)] border-b border-solid grid grid-cols-[25%_75%] gap-4"
    : styles.lnRow;

  const data = Object.entries({
    style: fullWizardData?.style,
    year: fullWizardData?.year,
    size: fullWizardData?.size,
    jerseyType: fullWizardData?.jerseyType,
    league: fullWizardData?.league
  }).map(([key, value]) => {
    const field = barcelonaConfig.FieldMap.find(f => f.Field === key);
    return field
      ? { name: field.Name, value: field.Values[value] || value }
      : { name: key, value };
  });

  data.push({ name: "Quantity", value: Object.values((fullWizardData?.qty as Record<string, number>) || {}).reduce((sum, value) => sum + value, 0) });

  return (
    <>
      <div className={[styles.card, columns, styles.lnCard, isPrintView && "text-sm"].join(' ')}>
        <h3 className={`uppercase text-[#F36E29] font-bold ${!isPrintView ? 'pt-[1.5em] pb-0 px-[1.5em]' : 'mb-4'}`}>Design Details</h3>

        {/* Design Details */}
        <div className={`${styles.cardBody} ${isPrintView ? "mr-2" : ""}`}>
          {!isPrintView &&
            <div className={rowStyle}>
              <span className={[styles.value, "pb-2 text-lg text-center w-full"].join(' ')}>Barcelona Name & Number Kits</span>
            </div>
          }
          {data?.length ? data.map(({ name, value }) => {
            const displayValue = name === "Quantity" ? `${value} Packs` : value;

            return displayValue ? (
              <div className={rowStyle} key={name}>
                <span className={styles.name}>{`${name}:`}</span>
                <span className={styles.value}>{displayValue}</span>
              </div>
            ) : null;
          }) : null}
        </div>
      </div>

      {/* Roster Grid */}
      {
        displayRoster && fullWizardData &&
        <div className={[styles.card, columns, styles.bgWhite].join(' ')}>
          <RosterGrid wizardData={rosterData} isPrintView={isPrintView} />
        </div>
      }
    </>
  );
};

export default BarcelonaDetails;