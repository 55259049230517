import { proxy } from "valtio";

export const relinkDesign = proxy({
  open: false,
  processing: false,
  data: null as { docId: string; docNo: string; lineNo: number; response: any } | null,

  openModal(data: { docId: string; docNo: string; lineNo: number; response: any }) {
    relinkDesign.open = true;
    relinkDesign.data = data;
  },

  closeModal() {
    relinkDesign.open = false;
    relinkDesign.data = null;
    relinkDesign.processing = false;
  },
});