import { useEffect } from "react"
import { useSnapshot } from 'valtio'
import appModel from "./models/app-model"
import DesignDetailPage from "./pages/design-detail"
import HistoryDetailPage from "./pages/history-detail"
import ArtDashboardPage from "./pages/art-dashboard/index"
import { init } from "./models/app-actions"
import { AppPages } from "./app-pages"
import { UCMProductionPage } from "./pages/ucm-production"
import InitFailPage from "./pages/init-fail"
import Spinner from "./pages/design-detail/comps/spinner"
import RelinkDesignModal from "./pages/design-detail/comps/relinkDesignModal"

// Styles
import './index.css';

export default function AppRouter() {
  const appSnap = useSnapshot(appModel);

  useEffect(() => {
    init()
      .catch((error) => {
        console.error("Error loading application state on Init:", error);
        throw error;
      })
  }, []);

  switch (appSnap.page) {
    case AppPages.Loading:
      return (<div className="m05">
        <Spinner spinning={true} color="#F36E29" />
      </div>)
    case AppPages.DetailsArtist:
    case AppPages.DetailsCustomer:
    case AppPages.DetailsProduction:
      return <DesignDetailPage />
    case AppPages.DetailsUCMProduction:
      return <UCMProductionPage />
    case AppPages.DetailsHistory:
      return <HistoryDetailPage />
    case AppPages.Dashboard:
      return <ArtDashboardPage />
    case AppPages.InitFail:
      return <InitFailPage />
    case AppPages.Relink:
      return <RelinkDesignModal />
    default:
      throw new Error(`Invalid app state: ${appSnap.page}`);
  }
}