import { InfoProps } from "../pages/design-detail/comps/info";

export interface CardConfig {
  title: string;
  properties: { key: keyof InfoProps; label: string }[];
}

export const getLabelForKey = (key: string): string => {
  const configs = cardConfigs;
  for (const config of configs) {
    const property = config.properties.find((prop: any) => prop.key === key);
    if (property) {
      return property.label;
    }
  }
  return letterAndNumberCamelCase(key);
};

export const letterAndNumberCamelCase = (key: string): string => {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/(\d)/g, ' $1 ')
    .replace(/\s+/g, ' ')
    .trim()
    .replace(/\b\w/g, (char) => char.toUpperCase());
};


export function getFileNameExtension(filename: string): string {
  const parts = filename?.split('.');
  if (parts && parts.length > 1) {
    return parts.pop()!.toUpperCase();
  }
  return '';
}

export default function mapDataToCards(isLNTransfer: boolean, isEasyView: boolean): CardConfig[] {
  let configs: CardConfig[];

  if (isLNTransfer) {
    configs = cardLNConfigs;
  } else if (isEasyView) {
    configs = cardEasyViewConfigs;
  } else {
    configs = cardConfigs;
  }

  const usedKeys = new Set<string>();
  configs.forEach(card => {
    card.properties.forEach(property => usedKeys.add(String(property.key)));
  });

  const addNewProperties = (properties: { key: keyof InfoProps; label: string }[]) => {
    return properties
      .filter(property => !usedKeys.has(String(property.key)))
      .map(property => ({
        key: property.key,
        label: property.label
      }));
  };

  configs.forEach(card => {
    card.properties = [
      ...card.properties,
      ...addNewProperties(card.properties)
    ];
  });

  return configs;
}

// Barcelona 
interface FieldMapEntry {
  Field: string;
  Name: string;
  Values: Record<string, string>;
}

interface BarcelonaConfig {
  FieldMap: FieldMapEntry[];
}

export const barcelonaConfig: BarcelonaConfig = {
  FieldMap: [
    { Field: "style", Name: "Style", Values: { L: "Letter", N: "Number", B: "Name" } },
    { Field: "year", Name: "Year", Values: { T25: "24/25" } },
    { Field: "size", Name: "Size", Values: { A: "Adult", J: "Junior" } },
    { Field: "jerseyType", Name: "Jersey Type", Values: { CG: "HOME" } },
    { Field: "league", Name: "League", Values: { P: "PLAY" } }
  ]
};

export const itemOptions = [
  { Key: "T25CGPAB27|Pack", Text: "LAMINE YAMAL" },
  { Key: "T25CGPABC09|Pack", Text: "LEWANDOWSKI" },
  { Key: "T25CGPALAA|Pack", Text: "A" },
  { Key: "T25CGPALBB|Pack", Text: "B" },
  { Key: "T25CGPALCC|Pack", Text: "C" },
  { Key: "T25CGPALDD|Pack", Text: "D" },
  { Key: "T25CGPALEE|Pack", Text: "E" },
  { Key: "T25CGPALFF|Pack", Text: "F" },
  { Key: "T25CGPALGG|Pack", Text: "G" },
  { Key: "T25CGPALHH|Pack", Text: "H" },
  { Key: "T25CGPALII|Pack", Text: "I" },
  { Key: "T25CGPALJJ|Pack", Text: "J" },
  { Key: "T25CGPALKK|Pack", Text: "K" },
  { Key: "T25CGPALLL|Pack", Text: "L" },
  { Key: "T25CGPALMM|Pack", Text: "M" },
  { Key: "T25CGPALNN|Pack", Text: "N" },
  { Key: "T25CGPALOO|Pack", Text: "O" },
  { Key: "T25CGPALPP|Pack", Text: "P" },
  { Key: "T25CGPALQQ|Pack", Text: "Q" },
  { Key: "T25CGPALRR|Pack", Text: "R" },
  { Key: "T25CGPALSS|Pack", Text: "S" },
  { Key: "T25CGPALTT|Pack", Text: "T" },
  { Key: "T25CGPALUU|Pack", Text: "U" },
  { Key: "T25CGPALVV|Pack", Text: "V" },
  { Key: "T25CGPALWW|Pack", Text: "W" },
  { Key: "T25CGPALXX|Pack", Text: "X" },
  { Key: "T25CGPALYY|Pack", Text: "Y" },
  { Key: "T25CGPALZZ|Pack", Text: "Z" },
  { Key: "T25CGPAN00|Pack", Text: "0" },
  { Key: "T25CGPAN01|Pack", Text: "1" },
  { Key: "T25CGPAN02|Pack", Text: "2" },
  { Key: "T25CGPAN03|Pack", Text: "3" },
  { Key: "T25CGPAN04|Pack", Text: "4" },
  { Key: "T25CGPAN05|Pack", Text: "5" },
  { Key: "T25CGPAN06|Pack", Text: "6" },
  { Key: "T25CGPAN07|Pack", Text: "7" },
  { Key: "T25CGPAN08|Pack", Text: "8" },
  { Key: "T25CGPAN09|Pack", Text: "9" },
  { Key: "T25CGPAXAC|Pack", Text: "CIRCUNFLEX" },
  { Key: "T25CGPAXAT|Pack", Text: "TANCAT" },
  { Key: "T25CGPAXGUIO|Pack", Text: "GUIO" },
  { Key: "T25CGPAXNY|Pack", Text: "TITLLA" },
  { Key: "T25CGPJB27|Pack", Text: "LAMINE YAMAL" },
  { Key: "T25CGPJLAA|Pack", Text: "A" },
  { Key: "T25CGPJLBB|Pack", Text: "B" },
  { Key: "T25CGPJLCC|Pack", Text: "C" },
  { Key: "T25CGPJLDD|Pack", Text: "D" },
  { Key: "T25CGPJLEE|Pack", Text: "E" },
  { Key: "T25CGPJLFF|Pack", Text: "F" },
  { Key: "T25CGPJLGG|Pack", Text: "G" },
  { Key: "T25CGPJLHH|Pack", Text: "H" },
  { Key: "T25CGPJLII|Pack", Text: "I" },
  { Key: "T25CGPJLJJ|Pack", Text: "J" },
  { Key: "T25CGPJLKK|Pack", Text: "K" },
  { Key: "T25CGPJLLL|Pack", Text: "L" },
  { Key: "T25CGPJLMM|Pack", Text: "M" },
  { Key: "T25CGPJLNN|Pack", Text: "N" },
  { Key: "T25CGPJLOO|Pack", Text: "O" },
  { Key: "T25CGPJLPP|Pack", Text: "P" },
  { Key: "T25CGPJLQQ|Pack", Text: "Q" },
  { Key: "T25CGPJLRR|Pack", Text: "R" },
  { Key: "T25CGPJLSS|Pack", Text: "S" },
  { Key: "T25CGPJLTT|Pack", Text: "T" },
  { Key: "T25CGPJLUU|Pack", Text: "U" },
  { Key: "T25CGPJLVV|Pack", Text: "V" },
  { Key: "T25CGPJLWW|Pack", Text: "W" },
  { Key: "T25CGPJLXX|Pack", Text: "X" },
  { Key: "T25CGPJLYY|Pack", Text: "Y" },
  { Key: "T25CGPJLZZ|Pack", Text: "Z" },
  { Key: "T25CGPJN00|Pack", Text: "0" },
  { Key: "T25CGPJN01|Pack", Text: "1" },
  { Key: "T25CGPJN02|Pack", Text: "2" },
  { Key: "T25CGPJN03|Pack", Text: "3" },
  { Key: "T25CGPJN04|Pack", Text: "4" },
  { Key: "T25CGPJN05|Pack", Text: "5" },
  { Key: "T25CGPJN06|Pack", Text: "6" },
  { Key: "T25CGPJN07|Pack", Text: "7" },
  { Key: "T25CGPJN08|Pack", Text: "8" },
  { Key: "T25CGPJN09|Pack", Text: "9" },
  { Key: "T25CGPJXAC|Pack", Text: "CIRCUNFLEX" },
  { Key: "T25CGPJXAT|Pack", Text: "TANCAT" },
  { Key: "T25CGPJXGUIO|Pack", Text: "GUIO" },
  { Key: "T25CGPJXNY|Pack", Text: "TITLLA" }
];

// Show art details by key (object properties)
export const cardConfigs: CardConfig[] = [
  {
    title: 'ART DETAILS',
    properties: [
      { key: 'artName', label: 'Artwork Name' },
      { key: 'cloudId', label: 'Artwork File' },
      { key: 'artDPI', label: 'Art DPI' },
      { key: 'artHeight', label: 'Art Height' },
      { key: 'artWidth', label: 'Art Width' },
      { key: 'aspectRatio', label: 'Aspect Ratio' },
      { key: 'bannerHeight', label: 'Banner Height' },
      { key: 'bannerWidth', label: 'Banner Width' },
      { key: 'designId', label: 'Design ID' },
      { key: 'fontHeight', label: 'Font Height' },
      { key: 'hasVector', label: 'Has Vector' },
      { key: 'isLegacyArt', label: 'Is Legacy Art' },
      { key: 'isRasterImage', label: 'Is Raster Image' },
      { key: 'origFileName', label: 'Original File Name' },
      { key: 'originalDesignId', label: 'Original Design ID' },
      { key: 'quantities', label: 'Quantities' },
      { key: 'uploadId', label: 'Upload ID' },
    ],
  },
  {
    title: 'PRODUCT DETAILS',
    properties: [
      { key: 'backgroundColor', label: 'Background Color' },
      { key: 'backgroundFabricColor', label: 'Patch Background Fabric Color' },
      { key: 'borderType', label: 'Border Type' },
      { key: 'buttonDistance', label: 'Distance between 2nd & 3rd button (6 inches max)' },
      { key: 'buttonPosition', label: 'Button Position' },
      { key: 'colorCount', label: 'Number of Colors/Colours' },
      { key: 'contrast', label: 'Contrast Selected' },
      { key: 'digitalColorHex', label: 'Digital Color Hex' },
      { key: 'digitalColorName', label: 'Digital Color Name' },
      { key: 'disableHeight', label: 'Disable Height' },
      { key: 'disableWidth', label: 'Disable Width' },
      { key: 'grommets', label: 'Grommets' },
      { key: 'heatAppliedSimulation', label: 'Heat Applied Simulation' },
      { key: 'heatMap', label: 'Heat Map' },
      { key: 'heightPx', label: 'Height Px' },
      { key: 'maxDimensionLarge', label: 'Largest Dimension Maximum' },
      { key: 'maxDimensionSmall', label: 'Smallest Dimension Maximum' },
      { key: 'media', label: 'Media Code' },
      { key: 'media_original', label: 'Media Type Description' },
      { key: 'mediaColor', label: 'Media Color' },
      { key: 'mediaText', label: 'Media Text' },
      { key: 'outlineColor', label: 'Contour Color' },
      { key: 'outlineType', label: 'Contour Type' },
      { key: 'patchBorderColor', label: 'Patch Border Thread Color' },
      { key: 'patchBorderType', label: 'Patch Border Type' },
      { key: 'patchColor', label: 'Patch Color' },
      { key: 'preProductionSampleFee', label: 'Pre-production Sample Fee' },
      { key: 'productType', label: 'Product Type' },
      { key: 'quoteQty', label: 'Quantity' },
      { key: 'serviceType', label: 'Service Type' },
      { key: 'sewAppliedToHat', label: 'Sew Applied to Hat' },
      { key: 'sewFile', label: 'Sew File' },
      { key: 'sewFileAppliedToHat', label: 'Sew Applied to Hat' },
      { key: 'sewFileAppliqueStop', label: 'Applique Stop' },
      { key: 'sewFileEmbroideryNotes', label: 'Sew Embroidery Notes' },
      { key: 'sewFileFormat', label: 'Sew File Format' },
      { key: 'sewFileQuiltedGarmentFlag', label: 'Sew Quilted Garment' },
      { key: 'sewFileType', label: 'Sew File Type' },
      { key: 'shape', label: 'Shape' },
      { key: 'shapeType', label: 'Shape Type' },
      { key: 'splitFront', label: 'Include Split Front' },
      { key: 'splitPosition', label: 'Split Position' },
      { key: 'splitStyle', label: 'Split Front Style' },
      { key: 'splitType', label: 'Split Type' },
      { key: 'selectedSampleOp', label: 'Pre-production Sample' },
      { key: 'widthPx', label: 'Width Px' },
      { key: 'quantityRequested', label: 'Quantity Requested' }
    ],
  },
  {
    title: 'MATERIAL SELECTION DETAILS',
    properties: [
      { key: 'color1Description', label: 'Color 1 Description' },
      { key: 'color1Material', label: 'Color 1 Material' },
      { key: 'color1MaterialColor', label: 'Color 1 Material Color' },
      { key: 'color1MaterialPattern', label: 'Color 1 Material Pattern' },
      { key: 'color1Pantone', label: 'Color 1 Pantone' },
      { key: 'color1StitchType', label: 'Color 1 Stitch Type' },
      { key: 'color1ThreadColor', label: 'Thread Color 1' },
      { key: 'color1WEIColor', label: 'Color 1' },
      { key: 'color1WEIColorType', label: 'Color 1 Type' },
      { key: 'color1ZZThreadColor', label: 'Color 1 Zig Zag Thread Color' },
      { key: 'color2Description', label: 'Color 2 Description' },
      { key: 'color2Material', label: 'Color 2 Material' },
      { key: 'color2MaterialColor', label: 'Color 2 Material Color' },
      { key: 'color2MaterialPattern', label: 'Color 2 Material Pattern' },
      { key: 'color2StitchType', label: 'Color 2 Stitch Type' },
      { key: 'color2ThreadColor', label: 'Thread Color 2' },
      { key: 'color2WEIColor', label: 'Color 2' },
      { key: 'color2WEIColorType', label: 'Color 2 Type' },
      { key: 'color2ZZThreadColor', label: 'Color 2 Zig Zag Thread Color' },
      { key: 'color3Description', label: 'Color 3 Description' },
      { key: 'color3Material', label: 'Color 3 Material' },
      { key: 'color3MaterialColor', label: 'Color 3 Material Color' },
      { key: 'color3MaterialPattern', label: 'Color 3 Material Pattern' },
      { key: 'color3StitchType', label: 'Color 3 Stitch Type' },
      { key: 'color3ThreadColor', label: 'Thread Color 3' },
      { key: 'color3WEIColor', label: 'Color 3' },
      { key: 'color3WEIColorType', label: 'Color 3 Type' },
      { key: 'color4Description', label: 'Color 4 Description' },
      { key: 'color4ThreadColor', label: 'Thread Color 4' },
      { key: 'color4WEIColor', label: 'Color 4' },
      { key: 'color4WEIColorType', label: 'Color 4 Type' },
      { key: 'color5Description', label: 'Color 5 Description' },
      { key: 'color5ThreadColor', label: 'Thread Color 5' },
      { key: 'color6Description', label: 'Color 6 Description' },
      { key: 'color6ThreadColor', label: 'Thread Color 6' },
      { key: 'color7Description', label: 'Color 7 Description' },
      { key: 'color7ThreadColor', label: 'Thread Color 7' },
      { key: 'color8Description', label: 'Color 8 Description' },
      { key: 'color8ThreadColor', label: 'Thread Color 8' },
      { key: 'color9Description', label: 'Color 9 Description' },
      { key: 'color9ThreadColor', label: 'Thread Color 9' },
      { key: 'colorAllMaterial', label: 'Material for all Layers' },
      { key: 'colorAllStitchType', label: 'Stitch Type for all Applicable Layers' },
      { key: 'garmentColor', label: 'Garment/Fabric Color' },
      { key: 'garmentFabric', label: 'Garment/Fabric Type' },
      { key: 'pantoneColor', label: 'Pantone Color' },
      { key: 'pantoneHex', label: 'Pantone Hex' },
      { key: 'sameMaterial', label: 'Use Same Material' },
      { key: 'sameStitchType', label: 'Use Same Stitch Type for All Layers' },
      { key: 'seamDistance', label: 'Distance between seams (6" max)' },
      { key: 'stitchType', label: 'Stitch Type' },
      { key: 'threadColor1Description', label: 'Thread Color 1 Description' },
      { key: 'threadColor2Description', label: 'Thread Color 2 Description' },
      { key: 'threadColor3Description', label: 'Thread Color 3 Description' },
      { key: 'threadColor4Description', label: 'Thread Color 4 Description' },
      { key: 'threadColor5Description', label: 'Thread Color 5 Description' },
      { key: 'threadColor6Description', label: 'Thread Color 6 Description' },
      { key: 'threadColor7Description', label: 'Thread Color 7 Description' },
      { key: 'threadColorCount', label: 'Number of Thread Colors' },
      { key: 'WEIColor1Description', label: 'Color 1 Description' },
      { key: 'WEIColor2Description', label: 'Color 2 Description' },
      { key: 'WEIColor3Description', label: 'Color 3 Description' },
      { key: 'WEIColor4Description', label: 'Color 4 Description' },
    ],
  },
];

export const mediaMap = {
  "3DEPHA": "",
  "3DEPPS": "",
  "DPDBN": "13 oz Matte Luster",
  "DPPCT": "Chroma-TWILL",
  "DPDE2": "Defense II",
  "DPXPT": "Express Print",
  "DPFLRL": "Floor Graphic",
  "DPDGF": "Glitter Flake",
  "DPCVGL": "Gloss Canvas",
  "DPBLGL": "Glossy Backlit",
  "DPPGL": "Glossy Paper",
  "DPPGLA": "Glossy Paper with Adhesive",
  "DPSTCL": "Helmet Sticker",
  "DPISP": "INSTA Print",
  "DPBLMA": "Matte Backlit",
  "DPSUT": "Perma-Twill",
  "DPDRE": "Permanent Dry Erase",
  "DPCVSA": "Satin Canvas",
  "DPSO2": "Soft Opaque II",
  "DPSTC": "Static Cling Clear",
  "DPSTW": "Static Cling White",
  "DPDWD": "Sticker/Decal",
  "DPSBT": "Sublimation Transfer",
  "DPSGL": "SuperTEK Gloss Clear",
  "DPSMA": "SuperTEK Matte Clear",
  "DPSTO": "SuperTEK Opaque",
  "DPSSU": "SuperTEK Sublistop",
  "DPTXT": "Texture Twill",
  "DPWAG": "Wall Graphic Fabric",
  "DPWFF": "Wall-Frog™ Fabric",
  "DPWFV": "WallFlair™ Vinyl",
  "CHPHA": "",
  "CHPPS": "",
  "SCR": "3M Reflective 5807",
  "FEA": "Acrylic Felt    ",
  "CCH": "Adhesive",
  "CBL": "Chroma Bling",
  "CHT": "Chroma-TWILL",
  "FFI": "Fashion-FILM",
  "FFE": "Fashion-FILM Electric",
  "FLC": "Flock",
  "FL2": "Flock II",
  "GFL": "Glitter Flake",
  "GLW": "Glow",
  "GGP": "GORILLA GRIP II",
  "HVR": "Hi-Vis Color Reflect",
  "HOL": "Hologram",
  "XPT": "Patterns (Express Print)",
  "PTW": "Perma-Twill",
  "PRV": "Permanent Sign Vinyl",
  "TWI": "Poly-TWILL      ",
  "PRP": "Premium Plus",
  "PHT": "Premium Plus High Tack",
  "PST": "PS Poly-TWILL",
  "RGL": "Reflective Glitter",
  "RII": "Reflective II",
  "GRV": "Removable Sign Vinyl",
  "SI2": "Silicone Dye-Block",
  "SFK": "Soft Flock",
  "SFM": "Soft Foam",
  "MET": "Soft Metallic",
  "SFL": "SportFilm Lite",
  "FLM": "Thermo-FILM     ",
  "GRP": "Thermo-GRIP     ",
  "ULW": "UltraWeed",
  "UVS": "UV Shift",
  "FEW": "Wool Felt       ",
  "EPPS": "",
  "PSPHA": "",
  "EPHA": "",
  "PSPPS": "",
  "EPHL": "Overlock",
  "PSPHL": "Overlock",
  "FSDEHACL": "Clear",
  "FSDEPSCL": "Clear",
  "FSFDHACL": "Clear",
  "FSFDPSCL": "Clear",
  "FSFDPSMA": "Matte",
  "FSFDHAMA": "Matte",
  "FSFDHLMA": "Matte",
  "FSDOHAMA": "Matte",
  "FSDOPSMA": "Matte",
  "FSDEPSMA": "Matte",
  "FSDEHAMA": "Matte",
  "FSBEHAMA": "Matte",
  "FSBEPSMA": "Matte",
  "FSBEPSME": "Metallic",
  "FSBEHAME": "Metallic",
  "FSDEHAME": "Metallic",
  "FSDEPSME": "Metallic",
  "FSDOPSME": "Metallic",
  "FSDOHAME": "Metallic",
  "FSFDHLME": "Metallic",
  "FSFDHAME": "Metallic",
  "FSFDPSME": "Metallic",
  "FSHOHA": "Matte",
  "FSHOPS": "Matte",
  "FLPHABLS": "Black/Silver",
  "FLPPSBLS": "Black/Silver",
  "LPPSCA": "Caramel",
  "LPHACA": "Caramel",
  "FLPPSCHB": "Chestnut/Black",
  "FLPHACHB": "Chestnut/Black",
  "FLPHACOB": "Chocolate/Black",
  "FLPPSCOB": "Chocolate/Black",
  "LPHANA": "Natural",
  "LPPSNA": "Natural",
  "FSPNBHA": "None",
  "FSPNBPS": "None",
  "FSPBHA": "Overlock",
  "FSPBPS": "Overlock",
  "FLPPSSAB": "Sandstone/Black",
  "FLPHASAB": "Sandstone/Black",
  "LPPSTA": "Taupe",
  "LPHATA": "Taupe",
  "PVCGLHA4CLR": "PVC Glow",
  "PVCGLPS4CLR": "PVC Glow",
  "PVCMAPS4CLR": "PVC Matte",
  "PVCMAHA4CLR": "PVC Matte",
  "DPTFI": "UltraColor MAX",
  "WVPHAMB": "Merrowed",
  "WVPPSMB": "Merrowed",
  "WVT": "None",
  "WVPPSOB": "Overlock",
  "WVPHAOB": "Overlock"
}

// LN Designs
export const cardLNConfigs: CardConfig[] = [
  {
    title: 'ART DETAILS',
    properties: [
      { key: 'applique', label: 'Appliqué' },
      { key: 'bottomsplitp', label: 'Bottom text split position' },
      { key: 'mw', label: 'Button Position' },
      { key: 'tailcutinword', label: 'Cut In Word' },
      { key: 'okdistort4custom', label: 'Distort' },
      { key: 'oktodrop4custom', label: 'Drop tail' },
      { key: 'font', label: 'Font' },
      { key: 'blockscript', label: 'Font Style' },
      { key: 'namedrop', label: 'Name Drop' },
      { key: 'namedroptype', label: 'Name Drop Font' },
      { key: 'namedropposition', label: 'Name Drop Position' },
      { key: 'namedroptext', label: 'Name Drop Text' },
      { key: 'PP', label: 'Preview Position' },
      { key: 'proofemail', label: 'Proof Email' },
      { key: 'ProofOnly', label: 'Proof Only' },
      { key: 'ssplit', label: 'Split Front' },
      { key: 'splitp', label: 'Split Position' },
      { key: 'toplayout', label: 'Text 1st Line Layout/Text Layout' },
      { key: 'bottomlayout', label: 'Text 2nd Line Layout' },
      { key: 'toptext', label: '1st Line Text' },
      { key: 'bottomtext', label: '2nd Line Text' },
      { key: 'bottomsize', label: '2nd Line Size' },
      { key: 'fontStyle', label: "Font Name" }
    ],
  },
  {
    title: 'PRODUCT DETAILS',
    properties: [
      { key: 'hCustomTypeSKU', label: 'Custom Type SKU' },
      { key: 'dbuttons4custom', label: 'Distance between 2nd & 3rd button (6 inches max)' },
      { key: 'dbuttons4zipper', label: 'Distance between seams (6" max)' },
      { key: 'edaitem', label: 'EDA Item' },
      { key: 'edaitemqty', label: 'EDA Item Quantity' },
      { key: 'sewDiskEmail', label: 'Email sew file' },
      { key: 'format', label: 'File Format' },
      { key: 'CMETYPES', label: 'Product Name' },
      { key: 'qty', label: 'Quantity' },
      { key: "quantity", label: "Number of items per kit" },
      { key: 'sku', label: 'SKU' },
      { key: 'diskitem', label: 'Sew Item Number' },
      { key: 'disk', label: 'Sew file?' },
      { key: 'u_of_m', label: 'Unit of Measure' },
    ],
  },
  {
    title: 'MATERIAL SELECTION DETAILS',
    properties: [
      { key: 'stitch2', label: '2nd Color Background Stitch Type' },
      { key: 'stitch3', label: '3rd Color Background Stitch Type' },
      { key: 'material3', label: '3rd Background Material' },
      { key: 'color', label: 'Color' },
      { key: 'color2', label: 'Background Color' },
      { key: 'color3', label: 'Background Color 2' },
      { key: 'layoutconnect', label: 'Background Connect/Foreground Cap Connect' },
      { key: 'material2', label: 'Background Material' },
      { key: 'layoutbk', label: 'Background Style' },
      { key: 'sku2t', label: 'Bottom One Color SKU' },
      { key: 'sku2b', label: 'Bottom Three Color SKU' },
      { key: 'sku2m', label: 'Bottom Two Color SKU' },
      { key: 'color1', label: 'Foreground Color' },
      { key: 'material1', label: 'Foreground Material' },
      { key: 'stitch', label: 'Foreground Stitch Type' },
      { key: 'tail', label: 'Has Tail?' },
      { key: 'tailname', label: 'Tail Name' },
      { key: 'tailtype', label: 'Tail Type' },
      { key: 'sku1t', label: 'Top One Color SKU' },
      { key: 'topsize', label: 'Top Size' },
      { key: 'topsizeother', label: 'Top Size Other' },
      { key: 'topsizeotherupto', label: 'Top Size Other Up To' },
      { key: 'sku1b', label: 'Top Three Color SKU' },
      { key: 'sku1m', label: 'Top Two Color SKU' },
      { key: 'velcroitem', label: 'Velcro' },
      { key: 'velcroqty', label: 'Velcro Quantity' },
    ],
  },
];

// EasyView Designs
export const cardEasyViewConfigs: CardConfig[] = [
  {
    title: 'ART DETAILS',
    properties: [
      { key: 'artDPI', label: 'Art DPI' },
      { key: 'artHeight', label: 'Art Height' },
      { key: 'artName', label: 'Artwork Name' },
      { key: 'artWidth', label: 'Art Width' },
      { key: 'aspectRatio', label: 'Aspect Ratio' },
      { key: 'bannerHeight', label: 'Banner Height' },
      { key: 'bannerWidth', label: 'Banner Width' },
      { key: 'cloudId', label: 'Artwork File' },
      { key: 'CustomArtworkNotes', label: 'Custom Artwork Notes' },
      { key: 'CustomArtworkRequiresChange', label: 'Requires Artwork Change' },
      { key: 'designId', label: 'Design ID' },
      { key: 'fontHeight', label: 'Font Height' },
      { key: 'HasNotes', label: 'Has Notes' },
      { key: 'hasVector', label: 'Has Vector' },
      { key: 'ImageHeight', label: 'Image Height' },
      { key: 'ImageWidth', label: 'Image Width' },
      { key: 'isLegacyArt', label: 'Is Legacy Art' },
      { key: 'isRasterImage', label: 'Is Raster Image' },
      { key: 'Notes', label: 'Notes' },
      { key: 'origFileName', label: 'Original File Name' },
      { key: 'originalDesignId', label: 'Original Design ID' },
      { key: 'quantities', label: 'Quantities' },
      { key: 'ShapeFigureCount', label: 'Shape Count' },
      { key: 'Simbroidery', label: 'Simbroidery' },
      { key: 'TXIdsInUse', label: 'TXIDs' },
      { key: 'uploadId', label: 'Upload ID' },
      { key: 'UserFlaggedRequiresArtistAttention', label: 'Requires Artist Attention' },
      { key: 'ArtBoardOrientation', label: 'Orientation' }
    ],
  },
  {
    title: 'PRODUCT DETAILS',
    properties: [
      { key: 'ApparelPreviewHistory', label: 'Apparel Preview History' },
      { key: 'ApparelShareHistory', label: 'Apparel Share History' },
      { key: 'ApprovedHeatMapDocId', label: 'Approved HeatMap DocId' },
      { key: 'backgroundColor', label: 'Background Color' },
      { key: 'backgroundFabricColor', label: 'Patch Background Fabric Color' },
      { key: 'borderType', label: 'Border Type' },
      { key: 'buttonDistance', label: 'Distance between 2nd & 3rd button (6 inches max)' },
      { key: 'buttonPosition', label: 'Button Position' },
      { key: 'colorCount', label: 'Number of Colors/Colours' },
      { key: 'contrast', label: 'Contrast Selected' },
      { key: 'digitalColorHex', label: 'Digital Color Hex' },
      { key: 'digitalColorName', label: 'Digital Color Name' },
      { key: 'disableHeight', label: 'Disable Height' },
      { key: 'disableWidth', label: 'Disable Width' },
      { key: 'grommets', label: 'Grommets' },
      { key: 'HeatMapDocId', label: 'HeatMap DocId' },
      { key: 'heatAppliedSimulation', label: 'Heat Applied Simulation' },
      { key: 'heatMap', label: 'Heat Map' },
      { key: 'heightPx', label: 'Height Px' },
      { key: 'IsTeamJob', label: 'Is Team Job' },
      { key: 'JobName', label: 'Job Name' },
      { key: 'LastPreviewedDocId', label: 'Last Previewed DocId' },
      { key: 'maxDimensionLarge', label: 'Largest Dimension Maximum' },
      { key: 'maxDimensionSmall', label: 'Smallest Dimension Maximum' },
      { key: 'media', label: 'Media Code' },
      { key: 'mediaColor', label: 'Media Color' },
      { key: 'mediaText', label: 'Media Text' },
      { key: 'media_original', label: 'Media Type Description' },
      { key: 'outlineColor', label: 'Contour Color' },
      { key: 'outlineType', label: 'Contour Type' },
      { key: 'patchBorderColor', label: 'Patch Border Thread Color' },
      { key: 'patchBorderType', label: 'Patch Border Type' },
      { key: 'patchColor', label: 'Patch Color' },
      { key: 'preProductionSampleFee', label: 'Pre-production Sample Fee' },
      { key: 'PrintMethod', label: 'Print Method' },
      { key: 'PrintMethodJson', label: 'Print Method Details' },
      { key: 'productType', label: 'Product Type' },
      { key: 'ProofSelected', label: 'Proof Selected' },
      { key: 'Quantity', label: 'Quantity' },
      { key: 'selectedSampleOp', label: 'Pre-production Sample' },
      { key: 'serviceType', label: 'Service Type' },
      { key: 'sewAppliedToHat', label: 'Sew Applied to Hat' },
      { key: 'sewFile', label: 'Sew File' },
      { key: 'sewFileAppliedToHat', label: 'Sew Applied to Hat' },
      { key: 'sewFileAppliqueStop', label: 'Applique Stop' },
      { key: 'sewFileEmbroideryNotes', label: 'Sew Embroidery Notes' },
      { key: 'sewFileFormat', label: 'Sew File Format' },
      { key: 'sewFileQuiltedGarmentFlag', label: 'Sew Quilted Garment' },
      { key: 'sewFileType', label: 'Sew File Type' },
      { key: 'shape', label: 'Shape' },
      { key: 'shapeType', label: 'Shape Type' },
      { key: 'SmallestAreaInInches', label: 'Smallest Area' },
      { key: 'SmallestSideInInches', label: 'Smallest Side' },
      { key: 'splitFront', label: 'Include Split Front' },
      { key: 'splitPosition', label: 'Split Position' },
      { key: 'splitStyle', label: 'Split Front Style' },
      { key: 'splitType', label: 'Split Type' },
      { key: 'StartedWithLayout', label: 'Started With Layout' },
      { key: 'StartedWithLayoutData', label: 'Started With Layout Data' },
      { key: 'TeamRoster', label: 'Team Roster' },
      { key: 'UberDesigner', label: 'Uber Designer' },
      { key: 'SessionId', label: 'Session Id' },
      { key: 'widthPx', label: 'Width Px' }
    ],
  },
  {
    title: 'MATERIAL SELECTION DETAILS',
    properties: [
      { key: "BlockerLayer", label: "Blocker Layer" },
      { key: "ClearLayer", label: "Clear Layer" },
      { key: "Colors", label: "Colors" },
      { key: 'color1Description', label: 'Color 1 Description' },
      { key: 'color1Material', label: 'Color 1 Material' },
      { key: 'color1MaterialColor', label: 'Color 1 Material Color' },
      { key: 'color1MaterialPattern', label: 'Color 1 Material Pattern' },
      { key: 'color1Pantone', label: 'Color 1 Pantone' },
      { key: 'color1StitchType', label: 'Color 1 Stitch Type' },
      { key: 'color1ThreadColor', label: 'Thread Color 1' },
      { key: 'color1WEIColor', label: 'Color 1' },
      { key: 'color1WEIColorType', label: 'Color 1 Type' },
      { key: 'color1ZZThreadColor', label: 'Color 1 Zig Zag Thread Color' },
      { key: 'color2Description', label: 'Color 2 Description' },
      { key: 'color2Material', label: 'Color 2 Material' },
      { key: 'color2MaterialColor', label: 'Color 2 Material Color' },
      { key: 'color2MaterialPattern', label: 'Color 2 Material Pattern' },
      { key: 'color2StitchType', label: 'Color 2 Stitch Type' },
      { key: 'color2ThreadColor', label: 'Thread Color 2' },
      { key: 'color2WEIColor', label: 'Color 2' },
      { key: 'color2WEIColorType', label: 'Color 2 Type' },
      { key: 'color2ZZThreadColor', label: 'Color 2 Zig Zag Thread Color' },
      { key: 'color3Description', label: 'Color 3 Description' },
      { key: 'color3Material', label: 'Color 3 Material' },
      { key: 'color3MaterialColor', label: 'Color 3 Material Color' },
      { key: 'color3MaterialPattern', label: 'Color 3 Material Pattern' },
      { key: 'color3StitchType', label: 'Color 3 Stitch Type' },
      { key: 'color3ThreadColor', label: 'Thread Color 3' },
      { key: 'color3WEIColor', label: 'Color 3' },
      { key: 'color3WEIColorType', label: 'Color 3 Type' },
      { key: 'color4Description', label: 'Color 4 Description' },
      { key: 'color4ThreadColor', label: 'Thread Color 4' },
      { key: 'color4WEIColor', label: 'Color 4' },
      { key: 'color4WEIColorType', label: 'Color 4 Type' },
      { key: 'color5Description', label: 'Color 5 Description' },
      { key: 'color5ThreadColor', label: 'Thread Color 5' },
      { key: 'color6Description', label: 'Color 6 Description' },
      { key: 'color6ThreadColor', label: 'Thread Color 6' },
      { key: 'color7Description', label: 'Color 7 Description' },
      { key: 'color7ThreadColor', label: 'Thread Color 7' },
      { key: 'color8Description', label: 'Color 8 Description' },
      { key: 'color8ThreadColor', label: 'Thread Color 8' },
      { key: 'color9Description', label: 'Color 9 Description' },
      { key: 'color9ThreadColor', label: 'Thread Color 9' },
      { key: 'colorAllMaterial', label: 'Material for all Layers' },
      { key: 'colorAllStitchType', label: 'Stitch Type for all Applicable Layers' },
      { key: "FabricColor", label: "Fabric Color" },
      { key: 'garmentColor', label: 'Garment/Fabric Color' },
      { key: 'garmentFabric', label: 'Garment/Fabric Type' },
      { key: "LayoutsUsed", label: "Layouts Used" },
      { key: "MISKey", label: "MIS Key" },
      { key: 'pantoneColor', label: 'Pantone Color' },
      { key: 'pantoneHex', label: 'Pantone Hex' },
      { key: "PricingImagesPerSheet", label: "Pricing Images Per Sheet" },
      { key: "RawImageHeight", label: "Raw Image Height" },
      { key: "RawImageWidth", label: "Raw Image Width" },
      { key: 'sameMaterial', label: 'Use Same Material' },
      { key: 'sameStitchType', label: 'Use Same Stitch Type for All Layers' },
      { key: 'seamDistance', label: 'Distance between seams (6" max)' },
      { key: 'stitchType', label: 'Stitch Type' },
      { key: 'threadColor1Description', label: 'Thread Color 1 Description' },
      { key: 'threadColor2Description', label: 'Thread Color 2 Description' },
      { key: 'threadColor3Description', label: 'Thread Color 3 Description' },
      { key: 'threadColor4Description', label: 'Thread Color 4 Description' },
      { key: 'threadColor5Description', label: 'Thread Color 5 Description' },
      { key: 'threadColor6Description', label: 'Thread Color 6 Description' },
      { key: 'threadColor7Description', label: 'Thread Color 7 Description' },
      { key: 'threadColorCount', label: 'Number of Thread Colors' },
      { key: "UserImports", label: "User Imports" },
      { key: 'WEIColor1Description', label: 'Color 1 Description' },
      { key: 'WEIColor2Description', label: 'Color 2 Description' },
      { key: 'WEIColor3Description', label: 'Color 3 Description' },
      { key: 'WEIColor4Description', label: 'Color 4 Description' },
    ],
  },
];

// Editable layer names
export const editableProperties = [
  'color1Description',
  'color2Description',
  'color3Description',
  'color4Description',
  'color5Description',
  'color6Description',
  'color7Description',
  'color8Description',
  'color9Description',
];


