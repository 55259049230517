import React from 'react';
import { useSnapshot } from 'valtio';
import appModel from '../../../models/app-model';
import Spinner from './spinner';
import { relinkDesign } from '../../../models/relink-design';
import { AppPages } from '../../../app-pages';
import { init } from '../../../models/app-actions';
import { linkDocument } from '../../../api/backend';

// Styles
import styles from './relinkDesignModal.module.css';

function CloseIcon() {
  return (
    <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
      <span className="sr-only">Close menu</span>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  );
}

const RelinkDesignModal: React.FC = () => {
  const { open, data, processing } = useSnapshot(relinkDesign);

  if (!open || !data) return null;

  const { docId, docNo, lineNo, response } = data;

  const handleCancel = () => {
    relinkDesign.closeModal();
    appModel.page = AppPages.InitFail;
    throw new Error('Error linking design');
  };

  const handleConfirm = async () => {
    relinkDesign.processing = true;

    try {
      await linkDocument(docId, docNo, lineNo, true);
      relinkDesign.closeModal();
      await init();
    } catch (error) {
      console.error("Error relinking design:", error);
      appModel.page = AppPages.InitFail;
    } finally {
      relinkDesign.processing = false;
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.header}>
            <span>Re-link Design</span>
            <div className={styles.closeIcon} onClick={handleCancel}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.modalContent}>
            <span>
              Design number <b>{response.designId}</b> is already linked to order number <b>{response.orderN}</b> and line number <b>{response.lineN}</b>.
            </span>
            <span>
              Do you want to unlink it and re-link to order number <b>{docNo}</b> and line number <b>{lineNo}</b>?
              <i> (Make sure you know what you are doing, this action is irreversible).</i>
            </span>
          </div>
          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={handleCancel}>
              Cancel
            </button>
            <button className={styles.acceptBtn} onClick={handleConfirm} disabled={processing}>
              {!processing ? "Confirm" : <Spinner spinning={processing} color="#d1d5db" size={{ width: "22px", height: "22px" }} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default RelinkDesignModal;
